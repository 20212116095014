import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 273.000000 286.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} strokeWidth="1" transform="translate(0.000000,286.000000) scale(0.100000,-0.100000)" stroke="none">
          <path d="M0 1430 l0 -1430 1365 0 1365 0 0 1430 0 1430 -1365 0 -1365 0 0
-1430z m1318 879 c136 -42 131 -35 152 -184 14 -95 47 -257 61 -302 10 -31 14
-33 55 -33 24 0 44 -5 44 -10 0 -6 8 -14 18 -17 9 -4 34 -18 54 -31 33 -21 36
-27 27 -46 -6 -11 -27 -38 -47 -58 l-35 -37 54 13 c116 27 246 118 219 152
-17 19 -94 42 -185 55 -92 12 -158 38 -174 67 -16 30 -13 100 5 132 18 30 89
80 114 80 9 0 21 5 26 10 22 22 286 90 349 90 117 0 120 -2 179 -113 l21 -38
-24 -18 c-20 -15 -41 -18 -135 -15 -114 2 -259 -21 -253 -40 2 -6 16 -14 33
-19 54 -18 103 -56 110 -85 4 -15 10 -29 14 -32 3 -3 16 -19 28 -37 34 -48 31
-126 -7 -186 -60 -93 -217 -177 -334 -177 -59 0 -65 -2 -71 -22 -21 -84 -126
-178 -197 -178 -52 0 -99 28 -99 60 0 14 -15 39 -37 62 l-36 37 27 11 c15 5
33 10 39 10 23 1 47 27 53 59 6 30 5 31 -27 31 -19 -1 -61 -7 -94 -15 -72 -18
-195 -20 -195 -4 0 6 -9 22 -20 36 -11 14 -20 35 -20 47 -1 19 -2 18 -16 -6
-8 -16 -19 -28 -24 -28 -6 0 -10 -11 -10 -25 0 -14 -4 -25 -9 -25 -9 0 -44
-100 -56 -162 -3 -17 -17 -33 -35 -43 -17 -9 -30 -20 -30 -24 0 -5 -18 -12
-41 -16 -34 -6 -46 -3 -79 20 -26 18 -41 37 -46 58 -3 18 -14 41 -23 51 -12
14 -13 20 -3 23 6 3 12 13 12 22 0 23 61 162 144 330 36 73 66 138 66 145 0
13 98 202 121 234 12 16 32 25 68 31 43 7 61 16 97 52 25 24 57 63 72 86 15
24 28 43 30 43 2 0 33 -9 70 -21z m-665 -1150 c11 -15 27 -51 37 -80 l18 -53
7 50 c4 27 12 57 17 67 10 20 39 22 56 5 9 -9 15 -9 24 0 7 7 22 12 35 12 26
0 34 -20 36 -96 2 -48 2 -49 -30 -52 -27 -3 -33 0 -33 17 0 15 -6 19 -27 18
-16 -1 -31 -9 -35 -18 -9 -22 -136 -26 -144 -4 -8 19 -43 19 -50 0 -7 -17 -39
-20 -49 -6 -7 13 31 114 57 146 25 32 54 30 81 -6z m1221 12 c23 -19 8 -44
-23 -38 l-26 5 28 -22 c23 -19 28 -29 25 -57 -3 -30 -8 -36 -42 -47 -39 -12
-108 -3 -121 17 -11 19 7 42 29 35 21 -6 21 -6 -1 18 -52 56 -14 113 69 105
26 -2 54 -10 62 -16z m-798 -27 c14 -18 24 -18 24 0 0 15 24 28 34 19 3 -4 6
-20 7 -37 0 -17 3 -24 6 -16 3 8 12 23 21 33 28 32 132 14 132 -24 0 -27 -21
-34 -37 -13 -12 16 -15 17 -23 4 -22 -35 -3 -59 29 -38 16 9 23 10 32 1 9 -9
5 -18 -14 -37 -35 -35 -89 -35 -122 -1 l-25 24 0 -24 c0 -20 -5 -25 -27 -25
-15 0 -37 10 -50 22 l-23 21 0 -21 c0 -15 -6 -22 -20 -22 -20 0 -26 15 -33 80
-2 22 -4 14 -5 -22 l-2 -58 -34 0 c-26 0 -35 5 -40 23 -8 29 -8 91 0 111 8 22
74 22 74 1 0 -8 5 -15 10 -15 6 0 10 6 10 14 0 28 54 28 76 0z m902 -31 c5
-80 26 -66 34 22 4 46 32 39 36 -9 7 -77 -18 -116 -73 -116 -51 0 -76 27 -80
85 -4 60 8 77 49 73 30 -3 31 -5 34 -55z m-576 22 c4 -32 22 -33 23 -2 0 26
37 36 61 17 10 -9 14 -9 14 -1 0 13 66 15 74 2 3 -4 3 -38 -1 -75 -6 -65 -6
-66 -35 -66 -16 0 -31 6 -34 15 -6 14 -8 14 -18 -1 -17 -22 -61 -15 -61 10 0
28 -23 31 -27 4 -2 -18 -10 -24 -34 -26 -28 -3 -32 0 -38 29 -16 84 -3 119 45
119 22 0 28 -5 31 -25z m789 14 c14 -7 19 -19 18 -37 -2 -15 0 -33 5 -40 12
-20 -3 -51 -28 -58 -12 -3 -42 -4 -67 -2 -37 2 -45 7 -53 29 -5 14 -6 47 -2
72 l7 47 50 0 c28 0 59 -5 70 -11z"/>
          <path d="M1198 1898 c-43 -52 -128 -185 -123 -190 7 -7 200 13 208 21 7 7 -35
196 -46 207 -2 3 -20 -15 -39 -38z"/>
          <path d="M2145 1110 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
          <path d="M2150 1060 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
        </g>
      </svg>



    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
