// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_ADMIN = '/secure';
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH, 
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};
export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  general: {
    app: path(ROOTS_ADMIN, '/app'),
    notif: path(ROOTS_ADMIN, '/notif'),
    info: path(ROOTS_ADMIN, '/info'),
    message: path(ROOTS_ADMIN, '/welcome'),
    feature: path(ROOTS_ADMIN,'/feature'),
    newfeature: path(ROOTS_ADMIN, '/newfeature'),
    app_download: path(ROOTS_ADMIN, '/appDownlod'),
    newapp_download: path(ROOTS_ADMIN, '/newappDownload'),
    payment_key: path(ROOTS_ADMIN,'/paymentKey'),
    calculator: path(ROOTS_ADMIN, '/calculator'),

    },
  user: {
  root: path(ROOTS_ADMIN, '/users'),
  all_user: path(ROOTS_ADMIN, '/users/users-all'),
  newUser: path(ROOTS_ADMIN, '/users/newuser'),
  creditUser: path(ROOTS_ADMIN, '/users/UserCredit'),
  upgradeuser: path(ROOTS_ADMIN, '/users/upgrade'),
  resetpassword: path(ROOTS_ADMIN, '/users/resetpassword'),
  automedaccount: path(ROOTS_ADMIN, '/users/autoAccount'),
  userbank: path(ROOTS_ADMIN, '/users/userbank'),
  bannednumber: path(ROOTS_ADMIN, '/users/banned'),
  addbanned: path(ROOTS_ADMIN, '/users/addbanned'),
  stockuser: path(ROOTS_ADMIN, '/users/stock')
  },
  sendmessage: {
  root: path(ROOTS_ADMIN, '/sendmessage'),
  gmail: path(ROOTS_ADMIN, '/sendmessage/gmail'),
  system: path(ROOTS_ADMIN, '/sendmessage/system'),
  bulksms: path(ROOTS_ADMIN, '/sendmessage/bulksms')
  },
  trans: {
   root: path(ROOTS_ADMIN, '/trans'),
   history: path(ROOTS_ADMIN, '/trans/history'),
   data: path(ROOTS_ADMIN, '/trans/data'),
   airtime: path(ROOTS_ADMIN, '/trans/airtime'),
   cable: path(ROOTS_ADMIN, '/trans/cable'),
   bill: path(ROOTS_ADMIN, '/trans/bill'),
   result: path(ROOTS_ADMIN, '/trans/result'),
   bulksms: path(ROOTS_ADMIN, '/trans/bulksms'),
   cash: path(ROOTS_ADMIN, '/trans/cash'),
   deposit: path(ROOTS_ADMIN, '/trans/deposit'),
   stock: path(ROOTS_ADMIN, '/trans/stock'),
   manual: path(ROOTS_ADMIN,'/trans/manual')
  },
  discount: {
    root: path(ROOTS_ADMIN, '/discount'),
    airtime: path(ROOTS_ADMIN, '/discount/airtime'),
    cash: path(ROOTS_ADMIN, '/discount/cash'),
    bill: path(ROOTS_ADMIN, '/discount/bill'),
    exam: path(ROOTS_ADMIN, '/discount/exam'),
    cable: path(ROOTS_ADMIN, '/discount/cable'),
    other: path(ROOTS_ADMIN, '/discount/other'),
  },
  lock: {
    root: path(ROOTS_ADMIN, '/lock'),
    airtime: path(ROOTS_ADMIN, '/lock/airtime'),
    data: path(ROOTS_ADMIN, '/lock/data'),
    exam: path(ROOTS_ADMIN, '/lock/result'),
    cable: path(ROOTS_ADMIN, '/lock/cable'),
    other: path(ROOTS_ADMIN, '/lock/other')
  },
  plan:{
    root: path(ROOTS_ADMIN, '/plan'),
    data: path(ROOTS_ADMIN, '/plan/data'),
    bill: path(ROOTS_ADMIN, '/plan/bill'),
    exam: path(ROOTS_ADMIN, '/plan/exam'),
    cable: path(ROOTS_ADMIN, '/plan/cable'),
    network: path(ROOTS_ADMIN, '/plan/network'),
    newdata: path(ROOTS_ADMIN, '/plan/newdata'),
    newcable: path(ROOTS_ADMIN, '/plan/newcable'),
    newbill: path(ROOTS_ADMIN, '/plan/newbill'),
    newresult: path(ROOTS_ADMIN, '/plan/newresult')
  },
  api:{
    root: path(ROOTS_ADMIN, '/api'),
    adex: path(ROOTS_ADMIN, '/api/adex'),
    virus: path(ROOTS_ADMIN, '/api/virus'),
    msorg: path(ROOTS_ADMIN, '/api/msorg'),
    other: path(ROOTS_ADMIN, '/api/other'),
    web:path(ROOTS_ADMIN, '/api/web')
  },
  selection: {
    root: path(ROOTS_ADMIN, '/selection'),
    data: path(ROOTS_ADMIN, '/selection/data'),
    airtime: path(ROOTS_ADMIN, '/selection/airtime'),
    cable: path(ROOTS_ADMIN, '/selection/cable'),
    bill: path(ROOTS_ADMIN, '/selection/bill'),
    bulksms: path(ROOTS_ADMIN, '/selection/bulksms'),
    exam: path(ROOTS_ADMIN, '/selection/exam'),
  }
}
export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    exam: path(ROOTS_DASHBOARD, '/exam'),
    buydata: path(ROOTS_DASHBOARD, '/buydata'),
    buyairtime: path(ROOTS_DASHBOARD, '/buyairtime'),
    buycable: path(ROOTS_DASHBOARD, '/buycable'),
    buybill: path(ROOTS_DASHBOARD, '/buybill'),
    cash: path(ROOTS_DASHBOARD, '/cash'),
    earning: path(ROOTS_DASHBOARD, '/earning'),
    bulksms: path(ROOTS_DASHBOARD, '/bulksms'),
    device: path(ROOTS_DASHBOARD, '/device'),
    notif: path(ROOTS_DASHBOARD, '/notif'),
    invioce: path(ROOTS_DASHBOARD, '/invoice'),
    stock: path(ROOTS_DASHBOARD, '/stock'),
    calculator: path(ROOTS_DASHBOARD, '/calculator'),
    pricing: path(ROOTS_DASHBOARD,'/pricing')
  },
  fund:{
  root: path(ROOTS_DASHBOARD, '/fund'),
  account: path(ROOTS_DASHBOARD, '/fund/account'),
  atm: path(ROOTS_DASHBOARD, '/fund/atm'),
  manual: path(ROOTS_DASHBOARD, '/fund/manual'),
  paystack: path(ROOTS_DASHBOARD, '/fund/paystack'),
  },
  trans: {
    root: path(ROOTS_DASHBOARD, '/trans'),
    history: path(ROOTS_DASHBOARD, '/trans/history'),
    data: path(ROOTS_DASHBOARD, '/trans/data'),
    airtime: path(ROOTS_DASHBOARD, '/trans/airtime'),
    cable: path(ROOTS_DASHBOARD, '/trans/cable'),
    bill: path(ROOTS_DASHBOARD, '/trans/bill'),
    result: path(ROOTS_DASHBOARD, '/trans/result'),
    bulksms: path(ROOTS_DASHBOARD, '/trans/bulksms'),
    cash: path(ROOTS_DASHBOARD, '/trans/cash'),
    deposit: path(ROOTS_DASHBOARD, '/trans/deposit'),
    stock: path(ROOTS_DASHBOARD, '/trans/stock'),
    airtimecash: path(ROOTS_DASHBOARD, '/trans/airtimecash'),
    manualfunding: path(ROOTS_DASHBOARD,'/trans/manualfunding'),
   },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
};


export const PATH_DOCS = "/documentation";
export const DOCS = {
  root: path(PATH_DOCS, '/home'),
  buydata: path(PATH_DOCS, '/data'),
  buyairtime: path(PATH_DOCS, '/airtime'),
  buycable: path(PATH_DOCS, '/cable'),
  buybill: path(PATH_DOCS, '/bill'),
  buyexam: path(PATH_DOCS, '/exam'),
  bulksms: path(PATH_DOCS, '/bulksms'),
  verifyiuc: path(PATH_DOCS, '/verify/iuc'),
  verifymetter: path(PATH_DOCS, '/verify/meter'),
  webhook: path(PATH_DOCS, '/webhook'),
}

